/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "orkney";
  src: url("/assets/fonts/Orkney-Regular.otf");
}
* {
  font-family: Roboto, "orkney", sans-serif;
  box-sizing: border-box;
}

body,
h1 {
  margin: 0;
  background-image: url("assets/img/portfolio-black.png");
  height: 100vh;
}

a {
  text-decoration: none;
  color: #000;
}
header {
  padding-top: 1px;
}

header .logo img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
header .logo span {
  letter-spacing: 1px;
  text-align: center;
  padding: 0 0 0 8px;
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: #1c4c6c;
}

.nav-ul {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
.nav-ul li {
  list-style-type: none;
  color: white;
  font-weight: bold;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#field1 {
  background: url("assets/img/field1.jpg") repeat-x 50% 0;
  background-position: 50% 0;
}
#field2 {
  background: url("assets/img/field2.jpg") repeat-x 50% 0;
  background-position: 50% 154px;
}
#field3 {
  background: url("assets/img/field3.jpg") repeat-x 50% 0;
  background-position: 50% 386px;
}
.wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  height: 625px;
}
.wrapper-middle {
  width: 90%;
  margin: 0 auto;
}

.logo {
  width: 260px;
  left: 50%;
  margin-left: -130px;
  background: url("assets/img/background-logo.png") no-repeat 50% 100%;
  padding: 40px 0 70px 0;
  display: block;
  position: absolute;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-star:hover {
  width: 80px;
  transition: all 0.5s ease-out;
}

// Spin animation star
@media (prefers-reduced-motion: no-preference) {
  .icon-star {
    animation: spin-star infinite 5s linear;
  }
}
@keyframes spin-star {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Animation star background

/* Config */
$color-bg: #1e1e1e;
$color-particle: #fff;
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 200s;

/* mixins */
@function particles($max) {
  $val: 0px 0px $color-particle;
  @for $i from 1 through $max {
    $val: #{$val}, random($spacing) + px random($spacing) + px $color-particle;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

.page-bg,
.animation-wrapper {
  position: fixed;
  z-index: -1;
}

.page-bg {
  // background: $color-bg;
  background-repeat: repeat-x;
  background-blend-mode: screen;
  background-size: cover;
  filter: grayscale(100%);
  z-index: -1;
}

.particle,
.particle:after {
  background: transparent;
}

.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}

.particle-1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(1000);
  height: 2px;
  width: 2px;
}

.particle-1:after {
  @include particles(900);
  height: 2px;
  width: 2px;
}

.particle-2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(800);
  height: 2px;
  width: 2px;
}

.particle-2:after {
  @include particles(500);
  height: 3px;
  width: 3px;
}

.particle-3:after {
  @include particles(1100);
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(1200);
  height: 1px;
  width: 1px;
}

.particle-4:after {
  @include particles(700);
  height: 1px;
  width: 1px;
}

@keyframes animParticle {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY($spacing * -1);
  }
}

/*glow for webkit*/

@-webkit-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow for mozilla*/

@-moz-keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}

/*glow*/

@keyframes neon1 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff1177,
      0 0 70px #ff1177, 0 0 80px #ff1177, 0 0 100px #ff1177, 0 0 150px #ff1177;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177,
      0 0 35px #ff1177, 0 0 40px #ff1177, 0 0 50px #ff1177, 0 0 75px #ff1177;
  }
}
// 768px max screen
@media screen and (max-width: 768px) {
  .logo {
    position: inherit;
    margin: auto;
  }

  .nav-ul {
    justify-content: space-around;
  }
  .flex-row {
    flex-direction: column;
  }
}
